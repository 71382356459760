import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';

import { AppConfigurationService, dateToLocalDateString } from '@app/utilities';

import { CropDevelopmentDataApiService } from './crop-development-data.api.service';
import { RegistrationsPUTRequest, RegistrationsResponse } from './crop-development-data.model';

@Injectable({
    providedIn: 'root',
})
export class RegistrationsApiService extends CropDevelopmentDataApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    private _baseUri: string = this._appConfigurationService.configuration.uris.registrationsService;

    protected get baseUri(): string {
        return this._baseUri;
    }

    private replaceCropId(cropId: string): string {
        return (this._baseUri = this._baseUri.replace(':cropId', cropId));
    }

    private resetBaseUri(): void {
        this._baseUri = this._appConfigurationService.configuration.uris.registrationsService;
    }

    public getRegistrations(
        cropId: string,
        characteristicsIds: string[],
        localStartDate: Dayjs,
        localEndDate: Dayjs,
    ): Observable<RegistrationsResponse> {
        this.resetBaseUri();
        this.replaceCropId(cropId);
        const params = this.getParams(characteristicsIds, localStartDate, localEndDate);
        return this.get<RegistrationsResponse>('', [], params);
    }

    private getParams(characteristicsIds: string[], localStartDate: Dayjs, localEndDate: Dayjs): HttpParams {
        let params = new HttpParams();
        characteristicsIds.forEach(
            (characteristicId) => (params = params.append('characteristicIds', characteristicId)),
        );
        params = params.append('localStartDate', dateToLocalDateString(localStartDate));
        params = params.append('localEndDate', dateToLocalDateString(localEndDate));
        return params;
    }

    public updateRegistrations(
        cropId: string,
        registrations: RegistrationsPUTRequest,
    ): Observable<RegistrationsResponse> {
        this.resetBaseUri();
        this.replaceCropId(cropId);

        return this.put<RegistrationsResponse>(``, <RegistrationsResponse>registrations);
    }
}
