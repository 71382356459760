import { Component, inject, input, OnInit, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { DpDatePickerModule, ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';

import { PrivaDatepickerModule, PrivaDatepickerSize } from '@priva/components/datepicker';
import { PrivaDatepickerThemeType } from '@priva/components/datepicker/lib/datepicker.model';

import {
    DateFormat,
    DatePickerService,
    DayFormat,
    LocaleService,
    MonthFormat,
    MonthYearFormat,
    YearFormat,
} from '@app/utilities';

dayjs.extend(isBetween);

@Component({
    selector: 'app-day-date-picker',
    standalone: true,
    imports: [DpDatePickerModule, PrivaDatepickerModule, FormsModule],
    templateUrl: './day-date-picker.component.html',
    styleUrl: './day-date-picker.component.scss',
})
export class DayDatePickerComponent implements OnInit {
    private readonly datePickerService = inject(DatePickerService);
    private readonly _localeService = inject(LocaleService);
    public dayDatePickerConfig: IDatePickerConfig;
    public minDate = input.required<Dayjs>();
    public maxDate = input.required<Dayjs>();
    public placeholder = input.required<string>();
    public date = input.required<Dayjs>();
    public displayDate = input<Dayjs>();
    public theme = input<PrivaDatepickerThemeType>('onlightgray');
    public dateFormat = input<string>(DateFormat);
    public isTableView = input<boolean>(false);
    public size = input<PrivaDatepickerSize>('md');
    public onChange = output<Dayjs | Dayjs[]>();

    public selectedDate = dayjs();

    constructor() {
        dayjs.locale(this._localeService.language);
    }

    public ngOnInit(): void {
        this.getDayDatePickerConfig();
        this.selectedDate = this.date();
    }

    public changeDate(date: Dayjs): void {
        if (!date) return;

        if (date.format(DateFormat) === this.selectedDate.format(DateFormat)) return;

        this.selectedDate = date;
        this.onChange.emit(date);
    }

    public onOpen(): void {
        if (!this.selectedDate) {
            this.selectedDate = this.displayDate();
        }
    }

    private getDayDatePickerConfig(): void {
        const minDate = this.minDate();
        const maxDate = this.maxDate();
        this.dayDatePickerConfig = this.datePickerService.createConfig({
            format: this.dateFormat(),
            dayBtnFormatter: (day: dayjs.Dayjs) => day.format(DayFormat),
            monthFormatter: (day: dayjs.Dayjs) => day.format(MonthYearFormat),
            monthBtnFormatter: (day: dayjs.Dayjs) => day.format(MonthFormat),
            yearFormatter: (day: dayjs.Dayjs) => day.format(YearFormat),
            returnedValueType: ECalendarValue.Dayjs,
            min: minDate,
            max: maxDate,
            locale: dayjs.locale(),
        } as IDatePickerConfig);
    }
}
