import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    inject,
    OnInit,
    signal,
    WritableSignal,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import isoWeek from 'dayjs/plugin/isoWeek';
import { DpDatePickerModule, ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';

import { PrivaSegmentService } from '@priva/analytics/segment';
import { PrivaButtonModule } from '@priva/components/button';
import { PrivaContentHeaderModule } from '@priva/components/content-header';
import { PrivaContentSectionModule } from '@priva/components/content-section';
import { PrivaDatepickerModule } from '@priva/components/datepicker';
import { PrivaHeaderModule } from '@priva/components/header';
import { PrivaIllustrationModule } from '@priva/components/illustration';
import { PrivaLinkModule } from '@priva/components/link';
import { PrivaLoaderModule } from '@priva/components/loader';
import { PrivaPopoverModule } from '@priva/components/popover';
import { PrivaSelectionGroupItem, PrivaSelectionGroupModule } from '@priva/components/selection-group';
import { PrivaSpinnerModule } from '@priva/components/spinner';
import { PrivaStatusGlobalModule } from '@priva/components/status-global';
import { PrivaTabListModule } from '@priva/components/tab-list';
import { PrivaTableModule } from '@priva/components/table';
import { PrivaTileModule } from '@priva/components/tile';
import { PrivaTitleModule } from '@priva/components/title';
import { PrivaLocalizationService, PrivaTranslateModule } from '@priva/localization';
import { selectCurrentCrops, selectCurrentCropsEarliestStartDate } from '@priva/masterdata';
import { PrivaToggleModule } from '@priva/utilities/toggle';

import { DayDatePickerComponent } from '@app/date-picker';
import { selectDashboardKpis } from '@app/monitoring';
import {
    DateFormat,
    DatePickerService,
    DayFormat,
    DayMonthFormat,
    LocaleService,
    MonthFormat,
    MonthYearFormat,
    YearFormat,
} from '@app/utilities';

import { DateRange } from '../models/range.models';
import { DateRequest, loadKPIsIfNeeded, selectIsLoading } from '../state/kpi-deviations';
import { ValueWithDeviationComponent } from '../value-with-deviation/value-with-deviation.component';
import { TableCellId as TableColumnID } from './crops-dashboard.model';
import { selectDashboardData } from './dashboard.selectors';

dayjs.extend(isoWeek);

const list = [
    {
        label: 'APP.DASHBOARD.DAILY.SELECT',
        id: DateRange.DAILY,
    },
    {
        label: 'APP.DASHBOARD.WEEKLY.SELECT',
        id: DateRange.WEEKLY,
    },
];

@Component({
    selector: 'app-crops-dashboard',
    standalone: true,
    imports: [
        PrivaContentSectionModule,
        PrivaTitleModule,
        PrivaTableModule,
        PrivaTileModule,
        PrivaButtonModule,
        PrivaTranslateModule,
        PrivaLinkModule,
        ValueWithDeviationComponent,
        PrivaPopoverModule,
        PrivaToggleModule,
        PrivaHeaderModule,
        PrivaContentHeaderModule,
        PrivaTabListModule,
        PrivaSelectionGroupModule,
        PrivaLoaderModule,
        PrivaSpinnerModule,
        DpDatePickerModule,
        PrivaDatepickerModule,
        DayDatePickerComponent,
        PrivaIllustrationModule,
        PrivaStatusGlobalModule,
    ],
    templateUrl: './crops-dashboard.component.html',
    styleUrl: './crops-dashboard.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropsDashboardComponent implements OnInit {
    private readonly datePickerService = inject(DatePickerService);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly _store: Store = inject(Store);
    private readonly analytics: PrivaSegmentService = inject(PrivaSegmentService);
    private readonly _localizationService = inject(PrivaLocalizationService);
    private readonly _localeService = inject(LocaleService);
    private readonly _cropsSignal = this._store.selectSignal(selectCurrentCrops);
    private readonly _kpiConfigsSignal = this._store.selectSignal(selectDashboardKpis);
    private readonly kpiIds = computed(() => {
        return this._kpiConfigsSignal()?.map((kpiConfig) => kpiConfig.id);
    });

    private locale = this._localeService.language;
    private yesterday = dayjs().locale(this.locale).subtract(1, 'day');
    private today = dayjs().locale(this.locale);
    private isMonday = signal(this.today.isoWeekday() === 1);

    public readonly cropIds = computed(() => {
        return this._cropsSignal()?.map((crop) => crop.id);
    });
    public readonly minWidth = 112;
    public readonly TableColumnID = TableColumnID;
    public readonly router: Router = inject(Router);
    public readonly maxDate = signal(this.yesterday);

    public selectedRequestDateRange: WritableSignal<DateRange> = signal(DateRange.DAILY);
    public selectedRequestDate = signal(this.yesterday);
    public dateRequest = computed(
        () =>
            <DateRequest>{
                dateRange: this.selectedRequestDateRange(),
                date: this.selectedRequestDate(),
            },
    );
    public weekDatePickerConfig: IDatePickerConfig;
    public isConfigReady = signal(false);
    public placeholderDate = signal(this.yesterday.format(DateFormat));
    public selectedDate = signal(this.yesterday);
    public displayDatePicker: WritableSignal<Dayjs> = signal(this.yesterday);
    public cropsEarliestStartDate = this._store.selectSignal(selectCurrentCropsEarliestStartDate);
    public items = computed(() => list);
    public selectedItem: WritableSignal<PrivaSelectionGroupItem> = signal(this.items()[0]);
    public dashboardData = this._store.selectSignal(selectDashboardData);
    public isLoading = this._store.selectSignal(selectIsLoading);
    public tooltipTable: Event[] = [];

    constructor() {
        dayjs.locale(this._localeService.language);
    }

    public ngOnInit(): void {
        this.generateWeekDatePicker();
        this.isConfigReady.set(true);
    }

    public onDateChange(selectedDate: Dayjs): void {
        this.isConfigReady.set(false);
        if (selectedDate) {
            if (this.selectedItem().id === DateRange.WEEKLY) {
                this.displayDatePicker.set(selectedDate);
                this.placeholderDate.set(this.getWeekDateFormat(selectedDate));
                this.selectedRequestDate.set(this.getWeekStartDate(selectedDate));
                this.updateWeekDatePicker();
            } else {
                this.selectedRequestDate.set(selectedDate);
                this.placeholderDate.set(selectedDate.format(DateFormat));
            }

            this._store.dispatch(
                loadKPIsIfNeeded({
                    cropIds: this.cropIds(),
                    kpiIds: this.kpiIds(),
                    dateRequest: this.dateRequest(),
                }),
            );
            this.isConfigReady.set(true);
        }
    }

    public onToggleChange(item: PrivaSelectionGroupItem) {
        if (item.id !== this.selectedItem().id) this.selectedItem.set(item);
        this.updateDates(item.id as DateRange);
        this._store.dispatch(
            loadKPIsIfNeeded({
                cropIds: this.cropIds(),
                kpiIds: this.kpiIds(),
                dateRequest: this.dateRequest(),
            }),
        );
    }

    public onSelectCrop({ cropId }) {
        this.analytics.track('Crop Row Clicked', {
            cropperformance_dashboard_crop_id: cropId,
        });
        this.router.navigate([`crops/dashboard/${cropId}/kpis`]);
    }

    private generateWeekDatePicker(): void {
        const minDate = this.cropsEarliestStartDate();
        const maxDate = this.yesterday;

        this.weekDatePickerConfig = this.datePickerService.createConfig({
            format: this.getWeekDateFormat(),
            dayBtnFormatter: (day: dayjs.Dayjs) => day.format(DayFormat),
            monthFormatter: (day: dayjs.Dayjs) => day.format(MonthYearFormat),
            monthBtnFormatter: (day: dayjs.Dayjs) => day.format(MonthFormat),
            yearFormatter: (day: dayjs.Dayjs) => day.format(YearFormat),
            returnedValueType: ECalendarValue.Dayjs,
            min: minDate,
            max: maxDate,
            locale: dayjs.locale(),
        } as IDatePickerConfig);
    }

    private updateDates(id: DateRange) {
        const date = this.getDefaultDate(id);
        this.displayDatePicker.set(dayjs(date));
        this.selectedRequestDateRange.set(id);
        this.selectedRequestDate.set(date);
        this.placeholderDate.set(
            id === DateRange.DAILY ? dayjs(date).format(DateFormat) : this.getWeekDateFormat(date),
        );
    }

    private getDefaultDate(dateRange: DateRange) {
        switch (dateRange) {
            case DateRange.WEEKLY:
                return this.isMonday() ? this.today.subtract(1, 'week') : this.today.startOf('isoWeek');
            case DateRange.DAILY:
            default:
                return this.yesterday;
        }
    }

    private getWeekStartDate(selectedDate: Dayjs) {
        return selectedDate.startOf('isoWeek');
    }

    private updateWeekDatePicker() {
        this.weekDatePickerConfig = {
            ...this.weekDatePickerConfig,
            format: this.getWeekDateFormat(),
        };

        this.cdr.detectChanges();
    }

    private getWeekDateFormat(date = this.selectedRequestDate()) {
        const endOfWeek = this.yesterday.isBefore(date.endOf('isoWeek'))
            ? this.yesterday
            : date.endOf('isoWeek');
        return `${this._localizationService.instant('APP.DATE.WEEK')} ${date.isoWeek()} (${date.startOf('isoWeek').format(DayMonthFormat)} - ${endOfWeek.format(DayMonthFormat)})`;
    }
}
