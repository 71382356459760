import dayjs, { Dayjs } from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import minMax from 'dayjs/plugin/minMax';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { LocalDateString } from './date-constants';

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(isLeapYear);
dayjs.extend(minMax);

export const dateToLocalDateString = (date: Dayjs): string => {
    return date.format(LocalDateString);
};

export const dateToLocalWeek = (date: Dayjs): string => {
    return `${date.isoWeekYear()}-${date.isoWeek().toString().padStart(2, '0')}`;
};

/**
 * Helper to create a consistent week key from a date.
 * Using isoWeekYear and isoWeek ensures consistent keys.
 */
export const getYearWeekKey = (date: Dayjs): string => {
    return `${date.isoWeekYear()}-${date.isoWeek()}`;
};
