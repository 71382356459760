import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import {
    combineLatest,
    EMPTY,
    filter,
    forkJoin,
    map,
    mergeMap,
    of,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs';

import {
    loadCompartmentsIfNeeded,
    loadCropsSuccess,
    loadExpandedCropsIfNeeded,
    selectCrops,
    selectCurrentCrops,
} from '@priva/masterdata';

import { selectAnomalyMetrics } from '@app/analysis';
import { DateRange, loadAnomaliesIfNeeded, loadKPIsIfNeeded } from '@app/crops';
import { loadDefinitionsIfNeeded } from '@app/definitions';
import { selectDashboardKpis } from '@app/monitoring';
import { FeatureAccessService } from 'app/common/routing/feature-access.service';

import {
    initializeFeatureFlags,
    loadCropsAndDefinitionsIfNeeded,
    loadCropsDataIfTriggered,
    loadFeatureFlagsSuccess,
    navigateToUrl,
    resetTriggeredState,
    validateActiveCrops,
    validateAndProcessRouteData,
    validateCrop,
} from './crops-page.actions';
import {
    selectCropsLoaded,
    selectFeatureFlags,
    selectIsLoadCropsAndDefinitionsTriggered,
} from './crops-page.selectors';

export enum urls {
    parent = '/',
    crops = '/crops',
    dashboard = `${urls.crops}/dashboard`,
    manage = `${urls.crops}/manage`,
    anomalies = `${urls.crops}/anomalies`,
}

export type FeatureFlags = Record<string, boolean>; // analysis, manageCrop

@Injectable({
    providedIn: 'root',
})
export class CropsPageEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _router = inject(Router);
    private readonly _store = inject(Store);
    private readonly _featureAccessService = inject(FeatureAccessService);

    /**
     * This effect listens to initializeFeatureFlags from app-main constructor.
     * It sets the allowed features for the user on load
     */
    public loadFeatureFlags$ = createEffect(() =>
        this._actions$.pipe(
            ofType(initializeFeatureFlags),
            mergeMap(() => {
                return forkJoin([
                    this._featureAccessService.showAnalysis(),
                    this._featureAccessService.showManageCrop(),
                ]).pipe(
                    map(([analysis, manageCrop]) => {
                        return loadFeatureFlagsSuccess({
                            featureFlags: {
                                analysis: analysis,
                                manageCrop: manageCrop,
                            },
                        });
                    }),
                );
            }),
        ),
    );

    /**
     * This effect listens to ROUTER_NAVIGATED for /crops url.
     * If the url is /crops we navigate to the dashboard if feature is allowed or manage if not.
     * If the url is /crops/? we trigger the routeChanged action.
     */
    public routerNavigated$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigatedAction) => action.payload.routerState.url.startsWith(urls.crops)),
            withLatestFrom(this._store.select(selectFeatureFlags)),
            switchMap(([action, featureFlags]) => {
                const requestedUrl = action.payload.routerState.url;
                const validUrl = this.getValidUrlForFeatureFlags(requestedUrl, featureFlags);

                if (requestedUrl === urls.crops) {
                    return of(navigateToUrl({ url: validUrl }));
                }

                const actionToDispatch = this.getActionForUrl(requestedUrl, featureFlags, validUrl);
                return of(actionToDispatch);
            }),
        ),
    );

    /**
     * This effect just asks if the crops are available in store
     * If they are not available it will trigger the loadCropsAndDefinitionsIfNeeded action.
     * If they are available it will trigger the loadCropsDataIfTriggered action.
     */
    public loadCropsAndDefinitionsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropsAndDefinitionsIfNeeded),
            withLatestFrom(this._store.select(selectCropsLoaded)),
            mergeMap(([_action, cropsLoaded]) => {
                if (cropsLoaded) {
                    return of(loadCropsDataIfTriggered());
                } else {
                    return of(loadExpandedCropsIfNeeded(), loadDefinitionsIfNeeded());
                }
            }),
        );
    });

    /**
     * This effect listens for crops are available.
     * Then it gets the crops from the store and the cropId from the url(if available).
     * And then triggers the validation process
     */
    public loadCropsDataIfTriggered$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropsDataIfTriggered),
            withLatestFrom(
                this._store.select(selectCrops),
                this._store.select(selectIsLoadCropsAndDefinitionsTriggered),
            ),
            switchMap(([_action, crops, isTriggered]) => {
                const cropId = this.extractCropId(this._router.url);
                if (!isTriggered) return EMPTY; // Do nothing if not triggered
                return of(validateAndProcessRouteData({ cropId: cropId, crops: crops }));
            }),
        );
    });

    /**
     * This effect listens for the loadCropSuccess fired from masterdata.
     * If the triggered state is true, then it gets the crops from the action and the cropId from the url(if available).
     * And then dispatch the validation process
     */
    public listenForLoadCropsSuccessValidateIfTriggered$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropsSuccess),
            withLatestFrom(this._store.select(selectIsLoadCropsAndDefinitionsTriggered)),
            switchMap(([action, isTriggered]) => {
                const cropId = this.extractCropId(this._router.url);
                if (!isTriggered) return EMPTY; // Do nothing if not triggered and loaded from /crops
                return of(validateAndProcessRouteData({ cropId: cropId, crops: action.crops }));
            }),
        );
    });

    /**
     * This effect take the props from the validateAndProcessRouteData action and decides which validation route to trigger
     */
    public validateAndProcessRouteData$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(validateAndProcessRouteData),
            map((action) => {
                const cropId = action?.cropId;
                if (cropId) {
                    return validateCrop({ cropId, crops: action.crops });
                } else {
                    return validateActiveCrops({ crops: action.crops });
                }
            }),
        );
    });

    /**
     * This effect will check if there are current crops.
     * If current crops exist navigation will continue to the dashboard, if not it will navigate to the manage page.
     */
    public validateActiveCrops$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(validateActiveCrops),
            withLatestFrom(this._store.select(selectCurrentCrops)),
            switchMap(([_action, currentCrops]) => {
                return combineLatest([
                    of(currentCrops),
                    this._store.select(selectDashboardKpis).pipe(filter((kpiConfigs) => !!kpiConfigs)),
                    this._store.select(selectAnomalyMetrics).pipe(filter((metrics) => !!metrics)),
                    this._store.select(selectFeatureFlags),
                ]);
            }),
            mergeMap(([currentCrops, kpiConfigs, metricConfigs, featureFlags]) => {
                if (!currentCrops || currentCrops.length === 0) {
                    return of(this.getActionForNoActiveCrops(featureFlags));
                }
                const cropIds = currentCrops.map(({ id }) => id);
                const kpiIds = kpiConfigs.map(({ id }) => id);
                const metricIds = metricConfigs.map(({ id }) => id);

                if (this._router.url === urls.dashboard) {
                    return [
                        loadKPIsIfNeeded({
                            cropIds,
                            kpiIds,
                            dateRequest: {
                                date: dayjs().subtract(1, 'day'),
                                dateRange: DateRange.DAILY,
                            },
                        }),
                        resetTriggeredState(),
                    ];
                } else if (this._router.url === urls.anomalies) {
                    return [
                        loadCompartmentsIfNeeded(),
                        loadAnomaliesIfNeeded({
                            cropIds,
                            metricIds: metricIds,
                            anomaliesRequestDate: dayjs().subtract(1, 'day'),
                        }),
                        resetTriggeredState(),
                    ];
                } else {
                    return EMPTY;
                }
            }),
        );
    });

    /**
     * This effect will check if the cropId matches any crop in the store.
     * If it does not exist it will navigate to '/'.
     */
    public validateCrop$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(validateCrop),
            filter((action) => !!action.cropId && !!action.crops),
            mergeMap((action) => {
                const { cropId, crops } = action;
                const crop = crops.find((crop) => crop.id === cropId);
                if (!crop) {
                    return of(navigateToUrl({ url: urls.parent }));
                } else {
                    return of(resetTriggeredState());
                }
            }),
        );
    });

    /**
     * This effect will navigate to the url provided.
     */
    public navigateToParent$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(navigateToUrl),
                tap((action) => {
                    this._router.navigate([action.url]);
                }),
            ),
        { dispatch: false },
    );

    private extractCropId(url: string): string {
        const segments = url.split('/');
        const idIndex = segments.findIndex((segment) => segment === 'dashboard');
        if (idIndex !== -1 && segments[idIndex + 1]) {
            return segments[idIndex + 1];
        }
        return '';
    }

    private urlValidationMap = {
        [urls.dashboard]: (featureFlags: FeatureFlags) => featureFlags.analysis,
        [urls.manage]: (featureFlags: FeatureFlags) => featureFlags.manageCrop,
        [urls.anomalies]: (featureFlags: FeatureFlags) => featureFlags.analysis,
    };

    private getValidUrlForFeatureFlags(url: string, featureFlags: FeatureFlags): string {
        for (const [key, validator] of Object.entries(this.urlValidationMap)) {
            if (url.startsWith(key)) {
                return validator(featureFlags) ? url : this.findValidFallbackUrl(featureFlags);
            }
        }
        return this.findValidFallbackUrl(featureFlags);
    }

    private findValidFallbackUrl(featureFlags: FeatureFlags): string {
        if (featureFlags.analysis) {
            return urls.dashboard;
        }
        if (featureFlags.manageCrop) {
            return urls.manage;
        }
        return urls.crops;
    }

    private getActionForUrl(url: string, featureFlags: FeatureFlags, validUrl: string): any {
        if (url === urls.manage && featureFlags.manageCrop) {
            return loadExpandedCropsIfNeeded();
        }
        if (featureFlags.analysis && url.startsWith(validUrl)) {
            return loadCropsAndDefinitionsIfNeeded();
        }
        return navigateToUrl({ url: validUrl });
    }

    private getActionForNoActiveCrops(featureFlags: FeatureFlags): any {
        if (featureFlags.manageCrop) {
            return [navigateToUrl({ url: urls.manage }), resetTriggeredState()];
        }
        return resetTriggeredState();
    }
}
