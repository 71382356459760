import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';

import { AppConfigurationService, dateToLocalDateString, dateToLocalWeek } from '@app/utilities';

import { CropMonitoringApiService } from '../crop-monitoring.api.service';
import { KpiDeviationsResponse } from './kpi-deviations.model';

@Injectable({
    providedIn: 'root',
})
export class KpiDeviationsApiService extends CropMonitoringApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    private _baseUri: string = this._appConfigurationService.configuration.uris.kpiDeviationsService;

    protected get baseUri(): string {
        return this._baseUri;
    }

    private replaceCropId(cropId: string): string {
        return (this._baseUri = this._baseUri.replace(':cropId', cropId));
    }

    private resetBaseUri(): void {
        this._baseUri = this._appConfigurationService.configuration.uris.kpiDeviationsService;
    }

    public getDayKpiDeviations(
        cropId: string,
        kpiIds: string[],
        start: Dayjs,
        end: Dayjs,
    ): Observable<KpiDeviationsResponse> {
        this.setRequest(cropId);
        let params = this.getParams(kpiIds);
        const localStartDate = dateToLocalDateString(start);
        const localEndDate = dateToLocalDateString(end);

        params = params.append('localStartDate', localStartDate);
        params = params.append('localEndDate', localEndDate);

        return this.get<KpiDeviationsResponse>(`dates/`, [], params);
    }

    public getWeekKpiDeviations(
        cropId: string,
        kpiIds: string[],
        start: Dayjs,
        end: Dayjs,
    ): Observable<KpiDeviationsResponse> {
        this.setRequest(cropId);
        let params = this.getParams(kpiIds);
        const localStartYearWeek = dateToLocalWeek(start);
        const localEndYearWeek = dateToLocalWeek(end);
        params = params.append('localStartYearWeek', localStartYearWeek);
        params = params.append('localEndYearWeek', localEndYearWeek);

        return this.get<KpiDeviationsResponse>(`weeks/`, [], params);
    }

    private getParams(kpiIds: string[]): HttpParams {
        let params = new HttpParams();

        kpiIds.forEach((kpiId) => (params = params.append('kpiIds', kpiId)));
        return params;
    }

    private setRequest(cropId: string) {
        this.resetBaseUri();
        this.replaceCropId(cropId);
    }
}
