// kpi state
import { Dayjs } from 'dayjs';

import { KpiDeviation, KpiDeviationsRequest } from '@app/monitoring';

import { DateRange } from '../../models/range.models';

export interface KpiDeviationsStateContainer {
    activeCropsKpiDeviations: KpiDeviationsState;
}

export interface KpiDeviationsState {
    cropsKpiDeviations: KpiDeviations[];
    cropKpiDeviationsRange: DateRequest;
    isLoading: boolean;
}

export interface KpiDeviations {
    request: KpiDeviationsRequest;
    deviations: KpiDeviation[];
}

export const initialKpiDeviations: KpiDeviationsState = {
    cropsKpiDeviations: undefined,
    cropKpiDeviationsRange: undefined,
    isLoading: false,
};

// for handling errors
export interface KpiDeviationsError {
    error: any;
    cropId: string;
}

export type CropKpisResponse = KpiDeviations | KpiDeviationsError;

// date request pass from components to store
export interface DateRequest {
    dateRange: DateRange;
    date: Dayjs;
}
