import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, map, Observable, take, tap } from 'rxjs';

import { FeatureAccessService } from 'app/common/routing/feature-access.service';

type AppGuardData = {
    redirectUrl: any[];
};
/**
 * This routing guard is used to check if the current user has
 * the required permissions to access the application.
 */
@Injectable({
    providedIn: 'root',
})
export class AppGuard {
    private readonly featureAccessService = inject(FeatureAccessService);
    private readonly router = inject(Router);

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        const routeData = route.data.appGuard as AppGuardData;

        return combineLatest([
            this.featureAccessService.showAnalysis(),
            this.featureAccessService.showManageCrop(),
        ]).pipe(
            take(1),
            map(([showAnalysis, showCropManagement]) => showAnalysis || showCropManagement),
            tap((canAccess) => {
                if (!canAccess) {
                    this.router.navigate([routeData.redirectUrl]);
                }
            }),
        );
    }
}
