export enum TableCellId {
    Crop = 'crop',
    Crops = 'crops',
    Kpi = 'kpi',
}

export interface ColumnCell {
    alignment: string;
    id: string;
    kpiId?: string;
    title: string;
    activeCrops?: number;
    unit?: string;
}

export interface RowCell {
    id: string;
    name?: string;
    cropId?: string;
    isAllTargetsSet?: boolean;
    kpiId?: string;
    value?: number;
    hasTarget?: boolean;
    deviation?: number;
    isOutOfBand?: boolean;
}

export type Row = RowCell[];
export type Column = ColumnCell[];

export type DashboardData = {
    cropRowData: Row[];
    columnData: Column;
};

export interface RowKpi {
    id: string;
    name: string;
    unit: string;
    kpiId: string;
    value: number;
    deviation: number;
    isOutOfBand: boolean;
    date: string;
    hasTarget: boolean;
}
