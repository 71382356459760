<app-crops-filter (dataSourceReady)="onDataSourceReady($event)" />
<priva-content-section class="margin-top-3">
    @if (dataSource && count$ | async) {
        <app-crops-table
            [crops$]="currentCrops$"
            [title]="'APP.CROPS.CURRENT'"
            [isClickable]="isAnalysisEnabled()"
            iconClass="far fa-seedling"
            data-test="table-current-crops"
        />
        <app-crops-table
            [crops$]="upcomingCrops$"
            [title]="'APP.CROPS.UPCOMING'"
            [isClickable]="isAnalysisEnabled()"
            iconClass="far fa-hand-holding-seedling"
            data-test="table-upcoming-crops"
        />
        <app-crops-table
            [crops$]="completedCrops$"
            [title]="'APP.CROPS.COMPLETED'"
            [isClickable]="isAnalysisEnabled()"
            iconClass="far fa-box"
            data-test="table-completed-crops"
        />
    } @else {
        @if (loaded) {
            <app-crops-empty />
        } @else {
            <priva-loader [hasFooter]="true">
                <priva-spinner size="lg" />
                <ng-container data-footer>{{ 'APP.CROPS.LOADING.CROPS' | translate }}</ng-container>
            </priva-loader>
        }
    }
</priva-content-section>
