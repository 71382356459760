export const MetricToCharacteristicIDMap: { [key: string]: string } = {
    '8a18d5ad-7778-461b-97b9-8a562229a841': '42dd224a-c5af-4c2d-950d-240db8f2ff43',
    '3070f998-f41b-4f12-8021-147737eddd3c': '9afd839c-e358-476d-b7a1-83cf20203551',
    '807785f7-64e1-47c9-9d4f-747bed82adc9': 'e7c1780f-f83a-4ed7-95c3-0eebd17563f9',
    '2fcdeabf-a09f-4eca-ac45-8635582f1ed1': '9e033528-8b87-461f-8c0b-48c758706a0e',
    '959b4a16-7efc-4e39-89fc-448db640becb': 'b483f8ce-99df-4755-b865-288a85f95651',
    '92f7ccf1-a61a-4f5c-a4ae-03e90ad44c02': '82d0625d-ce01-48d0-8315-879d34ef4422',
    'b4e9d5d9-9f23-4cb1-8f23-f263723b3141': '16880f13-5d1a-4867-a775-64695bb010d3',
    '16561bdc-68c3-4cca-8b58-777fb2dabba7': '22342161-c0a8-4d8d-af70-94e02f1c967d',
    '01fc4740-5de7-4b16-a131-a2855b3bbc3f': '194387b5-0304-4eaf-b83e-13ad47497e81',
    'b2dad421-9889-4005-823c-24d0d1e3a26a': 'a9674e23-c4bd-47b6-a085-3f53d648ffc1',
    '72c5cfa8-5c63-4407-8070-d716dbe0a41f': 'd77f60b7-7865-48da-84d9-d02d3b382820',
    '5b8486b7-23b8-43cc-afd5-f52d54e10bcf': '54ccbebd-06ff-4acd-90a7-c9518f5b8a4b',
    'b38e7e80-f457-4e03-8551-b43df64c7238': '38df4e5a-c74a-4f0c-8d6f-54830a4041e1',
    'fcb2dae5-1391-4d4e-97a2-87d9d411113c': 'b61a4096-7ac3-48ce-a7d4-5f5955b751da',
    '61cea8b7-b58c-4482-9829-b9f65f3303d8': 'd3c85d7a-6dea-47aa-8eea-eaae4ecda7d2',
    '752749c2-484e-4c82-a77c-00044a242af5': 'b6092c53-693b-4807-a504-b500617f444a',
    '02b6f775-ba11-4339-bc90-9e547240a320': '446773b1-8913-4adc-a07c-f0973aaf718b',
    'd5ed2fbc-6814-4b6f-8035-3f9cd86ae242': 'b77f8da4-58f8-4b2d-92fd-2291648b1a33',
    'c3b86c7c-53e4-4342-af2f-1c8893317076': '17c312f9-fe95-49fd-9126-fdf56c6f2183',
    'b62ce953-fcdd-4c52-84ab-16dd0c1b680d': '18873fb5-0911-4c79-b137-3dfdf0607f5b',
    '00128db8-ce0c-4baa-908b-436c0da486ac': '78c864aa-4259-4bc0-a5d2-6c1ca360f76d',
};
