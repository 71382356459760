export enum Metric {
    Temp24h = '8ab4f495-0797-4a5a-80c8-17ab63feb9f6',
    TempDay = 'f85655fa-cc3d-4ccb-af6e-6cb17bdd2975',
    TempNight = '55c6dffc-712b-4ee0-970c-b3dcfb449068',
    RadSumOut24h = 'cd297cd6-0f62-4c64-af91-53abda0dc3fd',
    CumRadSumOut = 'c10dd255-bc48-4601-bc14-7f9c5cd1a63b',
    Co224h = '080c294d-3d90-4e1c-80f1-61819da671a0',
    Co2Day = 'd4be3443-807f-4603-915b-55c1b74a5bc0',
    RH24h = '53a3813c-e688-4d48-800d-5d8ffbae4c85',
    RadSum24h = '5af6d6d0-7a35-4c3e-8d93-d99e1467ea76',
    WeekRadSum = '00982e6e-5969-4b4d-a2b6-eda74ef77c4b',
    ECDrain = 'e588ebee-1089-4402-88e5-4de3627df07d',
    PHDrain = '2458599d-6652-47a1-b31b-5983dc2c5c0b',
    // RTRatioAvg24hr = 'fae6ca22-8f9e-4f0e-900f-0ffe91351eb9',
    WaterUptake = 'dca38a8b-0bbd-4004-9cb7-a1868723e019',
    Dose = '96aa507d-6c60-4c70-b166-67b48669b015',
    Drain = '42a34022-04bd-451f-a595-ecf13d22eada',
    RTRPar = '6c124fdf-d2f8-4e47-b33f-fb2fd1909bc8',
    RTRRadSum = 'f0d4fff6-1e74-41f9-b2a1-2536fed7c740',
    Yield = '8a18d5ad-7778-461b-97b9-8a562229a841',
    SettingSpeed = '5b8486b7-23b8-43cc-afd5-f52d54e10bcf',
    RipeningTime = 'c3b86c7c-53e4-4342-af2f-1c8893317076',
    HarvestFruit = '3070f998-f41b-4f12-8021-147737eddd3c',
    HarvestTruss = '807785f7-64e1-47c9-9d4f-747bed82adc9',
    PlantGrowth = '2fcdeabf-a09f-4eca-ac45-8635582f1ed1',
    StemDiameter = '959b4a16-7efc-4e39-89fc-448db640becb',
    InternodeLength = '92f7ccf1-a61a-4f5c-a4ae-03e90ad44c02',
    FloweringHeight = 'b4e9d5d9-9f23-4cb1-8f23-f263723b3141',
    LeafLength = '16561bdc-68c3-4cca-8b58-777fb2dabba7',
    LeafWidth = '01fc4740-5de7-4b16-a131-a2855b3bbc3f',
    LeafCount = 'b2dad421-9889-4005-823c-24d0d1e3a26a',
    FloweringSpeed = '72c5cfa8-5c63-4407-8070-d716dbe0a41f',
    FlowersTruss = 'b38e7e80-f457-4e03-8551-b43df64c7238',
    FruitTruss = 'fcb2dae5-1391-4d4e-97a2-87d9d411113c',
    FruitStem = '61cea8b7-b58c-4482-9829-b9f65f3303d8',
    FruitLoad = '752749c2-484e-4c82-a77c-00044a242af5',
    SetFruitPerM2 = '02b6f775-ba11-4339-bc90-9e547240a320',
    SetFruitPerPlant = 'd5ed2fbc-6814-4b6f-8035-3f9cd86ae242',
    FruitWeight = 'b62ce953-fcdd-4c52-84ab-16dd0c1b680d',
    HeadDensity = '00128db8-ce0c-4baa-908b-436c0da486ac',
    Gas = 'deec8cb0-8089-451b-9f15-8206c2ab195e',
    Electricity = 'dce135a6-4d64-4461-8c4d-102c1b9e0403',
}
