import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PrivaToggleRouter } from '@priva/utilities/feature-toggles';

import { Features } from 'app/common/routing/features';

@Injectable({
    providedIn: 'root',
})
export class FeatureAccessService {
    private readonly toggleRouter = inject(PrivaToggleRouter);

    public showAnalysis(): Observable<boolean> {
        return of(this.toggleRouter.isEnabled(Features.Analysis));
    }

    public showManageCrop(): Observable<boolean> {
        return of(this.toggleRouter.isEnabled(Features.ManageCrop));
    }
}
