import { createSelector } from '@ngrx/store';

import { ExtendedCrop, selectExpandedCrops } from '@priva/masterdata';

import { KpiConfig } from '@app/kpis';
import { roundToMetricDecimals } from '@app/metrics';
import { KpiDeviation, selectDashboardKpis } from '@app/monitoring';

import { KpiDeviations, selectCropsDeviationsByRange } from '../state/kpi-deviations';
import { Column, ColumnCell, DashboardData, Row, RowCell, TableCellId } from './crops-dashboard.model';

export const selectDashboardData = createSelector(
    selectExpandedCrops,
    selectCropsDeviationsByRange,
    selectDashboardKpis,
    (crops: ExtendedCrop[], deviations: KpiDeviations[], kpis: KpiConfig[]): DashboardData => {
        if (!crops || !deviations || !kpis) {
            return { columnData: [], cropRowData: [] };
        }

        const deviationCropIds = new Set(deviations.map((dev) => dev.request.cropId));
        const deviationKpiIds = new Set(deviations.flatMap((dev) => dev.request.kpiIds));

        const filteredKpiConfigs = kpis.filter((kpi) => deviationKpiIds.has(kpi.id));
        const cropIds = Array.from(deviationCropIds);

        const columns = buildColumns(cropIds.length, filteredKpiConfigs);
        const rowData = deviations.map((dev) => buildRows(dev, crops));

        return {
            columnData: columns,
            cropRowData: rowData,
        };
    },
);

function buildRows(cropDeviations: KpiDeviations, crops: ExtendedCrop[]): Row {
    const { cropId, kpiIds } = cropDeviations.request;
    const cropName = crops.find((crop) => crop.id === cropId)?.name ?? '';
    const deviationsData = cropDeviations.deviations;
    return buildCropRow(cropId, cropName, kpiIds, deviationsData);
}

function buildColumns(cropCount: number, kpis: KpiConfig[]): Column {
    return [
        {
            id: TableCellId.Crops,
            alignment: 'start',
            title: 'APP.CROP_DASHBOARD.CROP',
            activeCrops: cropCount,
        },
        ...kpis.map(
            (kpi): ColumnCell => ({
                id: TableCellId.Kpi,
                alignment: 'start',
                kpiId: kpi.id,
                title: kpi.shortLabel,
                unit: kpi.unit,
            }),
        ),
    ];
}

function buildCropRow(cropId: string, cropName: string, kpiIds: string[], deviations: KpiDeviation[]): Row {
    const allValues = deviations.flatMap((dev) => dev.values);
    const isAllTargetsSet = allValues.every((kpi) => kpi.hasTarget);

    return [
        {
            id: TableCellId.Crop,
            name: cropName,
            cropId,
            isAllTargetsSet,
        },
        ...kpiIds.map((kpiId): RowCell => {
            const matchingDeviation = deviations.find((dev) => dev.kpiId === kpiId);
            const deviationValue = matchingDeviation?.values?.[0];

            return {
                id: TableCellId.Kpi,
                name: kpiId,
                value: roundToMetricDecimals(deviationValue?.value, kpiId),
                kpiId,
                hasTarget: deviationValue?.hasTarget,
                deviation: roundToMetricDecimals(deviationValue?.deviation, kpiId),
                isOutOfBand: deviationValue?.isOutOfBand,
            };
        }),
    ];
}
