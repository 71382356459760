import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { PrivaContentSectionModule } from '@priva/components/content-section';
import { PrivaTableModule } from '@priva/components/table';
import { PrivaTitleModule } from '@priva/components/title';
import { PrivaLocalizationService } from '@priva/localization';
import { CropSegmentLabels, CropTypeLabels, ExpandedCrop } from '@priva/masterdata';
import { PrivaTokens } from '@priva/styles/foundation';
import { PrivaToggleModule } from '@priva/utilities/toggle';

import { CropActionMenuComponent } from '@app/crop-action';
import { DateKeyToStringPipe } from '@app/utilities';

@Component({
    selector: 'app-crops-table',
    templateUrl: './crops-table.component.html',
    styleUrls: ['./crops-table.component.scss'],
    standalone: true,
    imports: [
        PrivaContentSectionModule,
        PrivaTitleModule,
        PrivaTableModule,
        CropActionMenuComponent,
        AsyncPipe,
        TranslateModule,
        DateKeyToStringPipe,
        PrivaToggleModule,
    ],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class CropsTableComponent {
    public router: Router = inject(Router);
    private translateService: PrivaLocalizationService = inject(PrivaLocalizationService);
    @Input() public crops$: Observable<ExpandedCrop[]>;
    @Input() public iconClass: string;
    @Input() public title: string;
    public readonly isClickable = input.required<boolean>();

    public dataTest = computed(
        () => this.translateService.instant(this.title).toLowerCase().replace(' ', '-') + '-title',
    );

    protected readonly window = window;
    protected readonly breakpointLg = +PrivaTokens.BreakpointLg.replace('px', '');
    protected readonly breakpointMdMid = +PrivaTokens.BreakpointMdMid.replace('px', '');
    protected readonly breakpointSmMid = +PrivaTokens.BreakpointSmMid.replace('px', '');
    protected readonly breakpointSm = +PrivaTokens.BreakpointSm.replace('px', '');

    public cropTypeLabels = CropTypeLabels;
    public cropSegmentLabels = CropSegmentLabels;

    public onCropClick({ row }) {
        this.router.navigate([`crops/dashboard/${row.id}/kpis`]);
    }
}
