/**
 * Define the feature toggles used to enable/disable functionality in the application
 * The string values here MUST match the names of the features as in access control.
 */
export enum Features {
    Analysis = 'priva.crop-performance.analysis',
    ManageCrop = 'priva.crop-performance.manage-crop',
}

export enum FeatureFlags {
    ANALYSIS = 'analysis',
    MANAGE_CROP = 'manageCrop',
}
