import {
    CropPlanMetrics,
    DashboardMetrics,
    KpiCropDevelopmentMetrics,
    KpiGrowingConditionMetrics,
    MetricConfig,
    MetricConfigs,
} from '@app/metrics';

import { KpiConfig } from './kpi-config-interface';

export const KpiConfigs: KpiConfig[] = mapMetricToKpiConfig(
    MetricConfigs.filter((metricConfig) => !!metricConfig.kpiId),
);

export const DashboardKpiConfigs = mapMetricToKpiConfig(DashboardMetrics);

export const KpiGrowingConditionsConfigs = mapMetricToKpiConfig(KpiGrowingConditionMetrics);

export const KpiCropDevelopmentConfigs = mapMetricToKpiConfig(KpiCropDevelopmentMetrics);

export const PlanKpiConfigs = mapMetricToKpiConfig(CropPlanMetrics);

function mapMetricToKpiConfig(metricConfigs: MetricConfig[]): KpiConfig[] {
    return metricConfigs.map(
        ({ id, testId, kpiId, category, isWeekly, unit, shortLabel, label, decimals, color }) => {
            return {
                id: kpiId,
                testId,
                category,
                label,
                shortLabel,
                color,
                decimals,
                isWeekly,
                unit,
                metricId: id,
            };
        },
    );
}
