export interface CropsPageStateContainer {
    cropsPage: CropsPageState;
}

export interface CropsPageState {
    isLoadCropsAndDefinitionsTriggered: boolean;
    isCropsLoaded: boolean;
    featureFlags: { [key: string]: boolean };
}

export const initialState: CropsPageState = {
    isLoadCropsAndDefinitionsTriggered: false,
    isCropsLoaded: false,
    featureFlags: {},
};
