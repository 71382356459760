@if (cropIds()?.length) {
    <priva-content-section class="anomalies-header margin-bottom-4">
        <div class="anomalies-header__date-select">
            <app-day-date-picker
                [minDate]="cropsEarliestStartDate()"
                [maxDate]="maxDate()"
                [placeholder]="placeholderDate()"
                [date]="displayDatePicker()"
                (onChange)="onDateChange($event)"
            />
        </div>
    </priva-content-section>

    <priva-content-section class="anomalies-main">
        <priva-tile [hasHeader]="false">
            @if (!isLoading() && tableData()) {
                <priva-table
                    type="collapsed"
                    class="anomalies-main__table"
                    [rows]="tableData().rowData"
                    [hasStickyHeader]="true"
                    [hasStickyFirstColumn]="true"
                    [isHighlighted]="isHighlighted"
                >
                    @for (column of tableData().columnData; track column; let idx = $index) {
                        <ng-template
                            [priva-table-column]="column.title"
                            [minWidth]="minWidth"
                            [justify]="'center'"
                            [alignment]="column.alignment || 'start'"
                            [headerTemplateRef]="{ template: headerTemplate, context: { data: column } }"
                            let-item="row"
                        >
                            @switch (column.id) {
                                @case (TableCellId.Crops) {
                                    <ng-container>
                                        <div class="anomaly-info">
                                            @if (item[idx]?.isCompartment) {
                                                <span class="compartment--indent">{{ item[idx]?.name }}</span>
                                            } @else {
                                                <strong>{{ item[idx]?.name }}</strong>
                                            }
                                        </div>
                                    </ng-container>
                                }
                                @case (TableCellId.Metric) {
                                    <div>
                                        @if (item[idx]?.hasAnomaly) {
                                            <priva-tag
                                                class="anomaly-value__tag"
                                                category="red"
                                                density="sm"
                                                size="xs"
                                                textOverflow="Truncate"
                                                [isRemovable]="false"
                                                >{{ (item[idx]?.value | numbertolocale) || '-' }}</priva-tag
                                            >
                                        } @else {
                                            <div class="anomaly-value__value">
                                                {{ (item[idx]?.value | numbertolocale) || '-' }}
                                            </div>
                                        }
                                    </div>
                                }
                            }
                        </ng-template>
                    }

                    <ng-template #headerTemplate let-column="data">
                        @switch (column.id) {
                            @case (TableCellId.Crops) {
                                <div [attr.data-test]="'anomalies-crops-counter'">
                                    {{ (column.title | translate) + ' (' + column.activeCrops + ')' }}
                                </div>
                            }
                            @case (TableCellId.Metric) {
                                <div>{{ column.title | translate }}</div>
                                <div class="metric-unit">{{ column.unit | translate }}</div>
                            }
                        }
                    </ng-template>
                </priva-table>
            } @else {
                <priva-loader [hasFooter]="true">
                    <priva-spinner size="lg" />
                    <ng-container data-footer>{{
                        'APP.ANOMALIES.LOADING.ANOMALIES' | translate
                    }}</ng-container>
                </priva-loader>
            }
        </priva-tile>
    </priva-content-section>
} @else {
    <priva-content-section isCentered="true">
        <priva-status-global title="{{ 'APP.CROPS.NO_CROPS.TITLE' | translate }}" [hasImage]="true">
            <priva-illustration illustrationName="general-empty-state" data-image />
        </priva-status-global>
    </priva-content-section>
}
