import { CharacteristicsMetrics, MetricConfig } from '@app/metrics';

import { CharacteristicsConfig } from './characteristics-config.interface';
import { MetricToCharacteristicIDMap } from './characteristics-id.map';

export const Characteristics = mapMetricToCharacteristicsConfig(CharacteristicsMetrics);

function mapMetricToCharacteristicsConfig(metricConfigs: MetricConfig[]): CharacteristicsConfig[] {
    return metricConfigs.map(({ id, testId, unit, shortLabel, label, decimals, color }) => {
        return {
            id: MetricToCharacteristicIDMap[id],
            testId,
            label,
            shortLabel,
            color,
            decimals,
            unit,
            metricId: id,
        };
    });
}
