import { Colors } from '../charts/chart-series-configs';
import { MetricConfig } from './metric-config.interface';
import { Metric } from './metric.enum';

export enum KpiCategories {
    Conditions = 'conditions',
    Development = 'development',
    Production = 'production',
}

export const Temp24hConfig: MetricConfig = {
    color: Colors['green1'],
    label: 'APP.ANALYSIS.TEMPERATURE.INSIDE',
    shortLabel: 'APP.METRIC.TEMP_INSIDE_SHORT',
    testId: 'temperature-inside',
    id: Metric.Temp24h,
    kpiId: Metric.Temp24h,
    category: KpiCategories.Conditions,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.CELSIUS',
};

export const TempDayConfig: MetricConfig = {
    color: Colors['red2'],
    label: 'APP.ANALYSIS.TEMPERATURE.DAY.INSIDE',
    shortLabel: 'APP.METRIC.TEMP_DAY_SHORT',
    testId: 'temperature-day',
    id: Metric.TempDay,
    kpiId: Metric.TempDay,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.CELSIUS',
};

export const TempNightConfig: MetricConfig = {
    color: Colors['red1'],
    label: 'APP.ANALYSIS.TEMPERATURE.NIGHT.INSIDE',
    shortLabel: 'APP.METRIC.TEMP_NIGHT_SHORT',
    testId: 'temperature-night',
    id: Metric.TempNight,
    kpiId: Metric.TempNight,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.CELSIUS',
};

export const RadSumOut24hConfig: MetricConfig = {
    color: Colors['orange2'],
    label: 'APP.METRIC.RAD',
    shortLabel: 'APP.METRIC.RAD_SHORT',
    testId: 'radiation-outside',
    id: Metric.RadSumOut24h,
    kpiId: Metric.RadSumOut24h,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.JOULE_PER_SQUARE_CENTIMETER',
};

export const CumRadSumOutConfig: MetricConfig = {
    color: Colors['red3'],
    label: 'APP.METRIC.CUM_RAD',
    shortLabel: 'APP.METRIC.CUM_RAD_SHORT',
    testId: 'radiation-outside-sum',
    id: Metric.CumRadSumOut,
    kpiId: Metric.CumRadSumOut,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.JOULE_PER_SQUARE_CENTIMETER',
};

// export const RTRatioAvg24hrConfig: MetricConfig = {
//     color: Colors['blue1'],
//     label: 'APP.ANALYSIS.RTR',
//     testId: 'RTR',
//     id: Metric.RTRatioAvg24hr,
//     decimals: 0,
//     unit: 'APP.UNIT.CELSIUS',
// };

export const WaterUptakeConfig: MetricConfig = {
    color: Colors['blue2'],
    label: 'APP.ANALYSIS.WATER_UPTAKE',
    shortLabel: 'APP.ANALYSIS.WATER_UPTAKE',
    testId: 'water-uptake',
    id: Metric.WaterUptake,
    kpiId: Metric.WaterUptake,
    category: KpiCategories.Conditions,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.LITER_PER_SQUARE_METER',
};

export const Co224hConfig: MetricConfig = {
    color: Colors['grey2'],
    label: 'APP.METRIC.H24_CO2',
    shortLabel: 'APP.METRIC.H24_CO2_SHORT',
    testId: 'co2concentration-24h',
    id: Metric.Co224h,
    kpiId: Metric.Co224h,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.PARTS_PER_MILLION',
};

export const Co2DayConfig: MetricConfig = {
    color: Colors['grey1'],
    label: 'APP.METRIC.DAY_CO2',
    shortLabel: 'APP.METRIC.DAY_CO2_SHORT',
    testId: 'co2concentration-day',
    id: Metric.Co2Day,
    kpiId: Metric.Co2Day,
    category: KpiCategories.Conditions,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.PARTS_PER_MILLION',
};

export const RH24hConfig: MetricConfig = {
    color: Colors['green3'],
    label: 'APP.METRIC.H24_RH',
    shortLabel: 'APP.METRIC.H24_RH_SHORT',
    testId: 'rh24h',
    id: Metric.RH24h,
    kpiId: Metric.RH24h,
    category: KpiCategories.Conditions,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.PERCENTAGE',
};

export const RadSum24hConfig: MetricConfig = {
    color: Colors['orange1'],
    label: 'APP.METRIC.H24RAD_SUM',
    shortLabel: 'APP.METRIC.H24RAD_SUM_SHORT',
    testId: 'rad-sum-24h',
    id: Metric.RadSum24h,
    kpiId: Metric.RadSum24h,
    category: KpiCategories.Conditions,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.JOULE_PER_SQUARE_CENTIMETER',
};

export const WeekRadSumConfig: MetricConfig = {
    color: Colors['orange3'],
    label: 'APP.METRIC.WKRAD_SUM',
    shortLabel: 'APP.METRIC.WKRAD_SUM_SHORT',
    testId: 'week-rad-sum',
    id: Metric.WeekRadSum,
    kpiId: Metric.WeekRadSum,
    category: KpiCategories.Conditions,
    decimals: 0,
    isWeekly: true,
    unit: 'APP.UNIT.JOULE_PER_SQUARE_CENTIMETER',
};

export const ECDrainConfig: MetricConfig = {
    color: Colors['brown1'],
    label: 'APP.METRIC.EC_DRAIN',
    shortLabel: 'APP.ANALYSIS.IRRIGATION_EC',
    testId: 'ec-drain',
    id: Metric.ECDrain,
    kpiId: Metric.ECDrain,
    category: KpiCategories.Conditions,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.MILLISIEMENS_PER_CENTIMETER',
};

export const PhDrainConfig: MetricConfig = {
    color: Colors['brown2'],
    label: 'APP.METRIC.PH_DRAIN',
    shortLabel: 'APP.ANALYSIS.IRRIGATION_PH',
    testId: 'ph-drain',
    id: Metric.PHDrain,
    kpiId: Metric.PHDrain,
    category: KpiCategories.Conditions,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.PH',
};

export const RTRParConfig: MetricConfig = {
    color: Colors['purple1'],
    label: 'APP.METRIC.RTR_PAR',
    shortLabel: 'APP.METRIC.RTR_PAR_SHORT',
    testId: 'rtr-par-sum',
    id: Metric.RTRPar,
    kpiId: Metric.RTRPar,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.CELSIUS_PER_10_MOL_PER_SQUARE_METER',
};

export const RTRRadSumConfig: MetricConfig = {
    color: Colors['purple1'],
    label: 'APP.METRIC.RTR_RAD_SUM',
    shortLabel: 'APP.METRIC.RTR_RAD_SUM_SHORT',
    testId: 'rtr-rad-sum',
    id: Metric.RTRRadSum,
    kpiId: Metric.RTRRadSum,
    category: KpiCategories.Conditions,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.CELSIUS',
};

export const DoseConfig: MetricConfig = {
    color: Colors['blue1'],
    label: 'APP.METRIC.DOSE',
    shortLabel: 'APP.METRIC.DOSE',
    testId: 'dose',
    id: Metric.Dose,
    kpiId: Metric.Dose,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.LITER_PER_SQUARE_METER',
};

export const DrainConfig: MetricConfig = {
    color: Colors['brown3'],
    label: 'APP.METRIC.DRAIN',
    shortLabel: 'APP.METRIC.DRAIN',
    testId: 'drain',
    id: Metric.Drain,
    kpiId: Metric.Drain,
    decimals: 1,
    isWeekly: false,
    unit: 'APP.UNIT.LITER_PER_SQUARE_METER',
};

export const YieldConfig: MetricConfig = {
    color: Colors['green3'],
    label: 'APP.METRIC.YIELD',
    shortLabel: 'APP.METRIC.YIELD_SHORT',
    testId: 'yield',
    id: Metric.Yield,
    kpiId: Metric.Yield,
    category: KpiCategories.Production,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.KILOGRAM_PER_SQUARE_METER',
};

export const SettingSpeedConfig: MetricConfig = {
    color: Colors['brown3'],
    label: 'APP.METRIC.SETTING_SPEED',
    shortLabel: 'APP.METRIC.SETTING_SPEED_SHORT',
    testId: 'setting-speed',
    id: Metric.SettingSpeed,
    kpiId: Metric.SettingSpeed,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.TRUSSES_PER_SQUARE_METER_PER_WEEK',
};

export const RipeningTimeConfig: MetricConfig = {
    color: Colors['orange3'],
    label: 'APP.METRIC.RIPENING_TIME',
    shortLabel: 'APP.METRIC.RIPENING_TIME_SHORT',
    testId: 'ripening-time',
    id: Metric.RipeningTime,
    kpiId: Metric.RipeningTime,
    category: KpiCategories.Development,
    decimals: 0,
    isWeekly: true,
    unit: 'APP.UNIT.DAYS',
};

export const HarvestFruitConfig: MetricConfig = {
    color: Colors['red2'],
    label: 'APP.METRIC.HARVEST_FRUIT',
    shortLabel: 'APP.METRIC.HARVEST_FRUIT_SHORT',
    testId: 'harvest-fruit',
    id: Metric.HarvestFruit,
    kpiId: Metric.HarvestFruit,
    category: KpiCategories.Production,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.FRUIT_PER_SQUARE_METER',
};

export const HarvestTrussConfig: MetricConfig = {
    color: Colors['red1'],
    label: 'APP.METRIC.HARVEST_TRUSS',
    shortLabel: 'APP.METRIC.HARVEST_TRUSS_SHORT',
    testId: 'harvest-truss',
    id: Metric.HarvestTruss,
    kpiId: Metric.HarvestTruss,
    category: KpiCategories.Production,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.TRUSS_PER_SQUARE_METER',
};

export const PlantGrowthConfig: MetricConfig = {
    color: Colors['brown3'],
    label: 'APP.METRIC.PLANT_GROWTH_PER_WEEK',
    shortLabel: 'APP.METRIC.PLANT_GROWTH_PER_WEEK_SHORT',
    testId: 'plant-growth',
    id: Metric.PlantGrowth,
    kpiId: Metric.PlantGrowth,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.CM_PER_WEEK',
};

export const StemDiameterConfig: MetricConfig = {
    color: Colors['brown2'],
    label: 'APP.METRIC.STEM_DIAMETER',
    shortLabel: 'APP.METRIC.STEM_DIAMETER_SHORT',
    testId: 'stem-dia',
    id: Metric.StemDiameter,
    kpiId: Metric.StemDiameter,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.MM',
};

export const InternodeLengthConfig: MetricConfig = {
    color: Colors['brown1'],
    label: 'APP.METRIC.INTERNODE_LENGTH',
    shortLabel: 'APP.METRIC.INTERNODE_LENGTH_SHORT',
    testId: 'internode-length',
    id: Metric.InternodeLength,
    kpiId: Metric.InternodeLength,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.CM',
};

export const FloweringHeightConfig: MetricConfig = {
    color: Colors['orange1'],
    label: 'APP.METRIC.FLOWERING_HEIGHT',
    shortLabel: 'APP.METRIC.FLOWERING_HEIGHT_SHORT',
    testId: 'flowering-height',
    id: Metric.FloweringHeight,
    kpiId: Metric.FloweringHeight,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.CM',
};

export const LeafLengthConfig: MetricConfig = {
    color: Colors['orange3'],
    label: 'APP.METRIC.LEAF_LENGTH',
    shortLabel: 'APP.METRIC.LEAF_LENGTH_SHORT',
    testId: 'leaf-length',
    id: Metric.LeafLength,
    kpiId: Metric.LeafLength,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.CM',
};

export const LeafWidthConfig: MetricConfig = {
    color: Colors['blue1'],
    label: 'APP.METRIC.LEAF_WIDTH',
    shortLabel: 'APP.METRIC.LEAF_WIDTH_SHORT',
    testId: 'leaf-width',
    id: Metric.LeafWidth,
    kpiId: Metric.LeafWidth,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.CM',
};

export const LeafCountConfig: MetricConfig = {
    color: Colors['blue2'],
    label: 'APP.METRIC.LEAF_COUNT',
    shortLabel: 'APP.METRIC.LEAF_COUNT_SHORT',
    testId: 'leaf-count',
    id: Metric.LeafCount,
    kpiId: Metric.LeafCount,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.NUMBER_PER_PLANT',
};

export const FloweringSpeedConfig: MetricConfig = {
    color: Colors['green1'],
    label: 'APP.METRIC.FLOWERING_SPEED',
    shortLabel: 'APP.METRIC.FLOWERING_SPEED_SHORT',
    testId: 'flowering-speed',
    id: Metric.FloweringSpeed,
    kpiId: Metric.FloweringSpeed,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.TRUSSES_PER_SQUARE_METER_PER_WEEK',
};

export const FlowersTrussConfig: MetricConfig = {
    color: Colors['orange2'],
    label: 'APP.METRIC.FLOWERS_PER_TRUSS',
    shortLabel: 'APP.METRIC.FLOWERS_PER_TRUSS_SHORT',
    testId: 'flowers-truss',
    id: Metric.FlowersTruss,
    kpiId: Metric.FlowersTruss,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.NUMBER_PER_TRUSS',
};

export const FruitTrussConfig: MetricConfig = {
    color: Colors['grey1'],
    label: 'APP.METRIC.FRUIT_PER_TRUSS',
    shortLabel: 'APP.METRIC.FRUIT_PER_TRUSS_SHORT',
    testId: 'fruit-truss',
    id: Metric.FruitTruss,
    kpiId: Metric.FruitTruss,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.NUMBER_PER_TRUSS',
};

export const FruitStemConfig: MetricConfig = {
    color: Colors['grey1'],
    label: 'APP.METRIC.FRUIT_PER_STEM',
    shortLabel: 'APP.METRIC.FRUIT_PER_STEM_SHORT',
    testId: 'fruit-stem',
    id: Metric.FruitStem,
    kpiId: Metric.FruitStem,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.NUMBER_PER_STEM',
};

export const FruitLoadConfig: MetricConfig = {
    color: Colors['blue1'],
    label: 'APP.METRIC.FRUIT_LOAD',
    shortLabel: 'APP.METRIC.FRUIT_LOAD_SHORT',
    testId: 'fruit-load',
    id: Metric.FruitLoad,
    kpiId: Metric.FruitLoad,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.NUMBER_PER_SQUARE_METER',
};

export const SetFruitPerM2Config: MetricConfig = {
    color: Colors['blue2'],
    label: 'APP.METRIC.SET_FRUIT_PER_SQUARE_METER',
    shortLabel: 'APP.METRIC.SET_FRUIT_PER_SQUARE_METER_SHORT',
    testId: 'fruit-load-m2',
    id: Metric.SetFruitPerM2,
    kpiId: Metric.SetFruitPerM2,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.NUMBER_PER_SQUARE_METER_PER_WEEK',
};

export const SetFruitPerPlantConfig: MetricConfig = {
    color: Colors['blue2'],
    label: 'APP.METRIC.SET_FRUIT_PER_PLANT',
    shortLabel: 'APP.METRIC.SET_FRUIT_PER_PLANT_SHORT',
    testId: 'set-fruit-plant',
    id: Metric.SetFruitPerPlant,
    kpiId: Metric.SetFruitPerPlant,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.NUMBER_PER_PLANT_PER_WEEK',
};

export const FruitWeightConfig: MetricConfig = {
    color: Colors['purple1'],
    label: 'APP.METRIC.FRUIT_WEIGHT',
    shortLabel: 'APP.METRIC.FRUIT_WEIGHT_SHORT',
    testId: 'fruit-weight',
    id: Metric.FruitWeight,
    kpiId: Metric.FruitWeight,
    category: KpiCategories.Production,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.GRAM',
};

export const HeadDensityConfig: MetricConfig = {
    color: Colors['grey3'],
    label: 'APP.METRIC.HEAD_DENSITY',
    shortLabel: 'APP.METRIC.HEAD_DENSITY_SHORT',
    testId: 'head-density',
    id: Metric.HeadDensity,
    kpiId: Metric.HeadDensity,
    category: KpiCategories.Development,
    decimals: 1,
    isWeekly: true,
    unit: 'APP.UNIT.NUMBER_PER_SQUARE_METER',
};

export const GasConfig: MetricConfig = {
    color: Colors['grey2'],
    label: 'APP.METRIC.GAS',
    shortLabel: 'APP.METRIC.GAS_SHORT',
    testId: 'gas',
    id: Metric.Gas,
    kpiId: Metric.Gas,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.CUBIC_METER',
};

export const ElectricityConfig: MetricConfig = {
    color: Colors['blue3'],
    label: 'APP.METRIC.ELECTRICITY',
    shortLabel: 'APP.METRIC.ELECTRICITY_SHORT',
    testId: 'electricity',
    id: Metric.Electricity,
    kpiId: Metric.Electricity,
    decimals: 0,
    isWeekly: false,
    unit: 'APP.UNIT.KILOWATT_HOUR',
};
