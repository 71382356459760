<dp-date-picker
    priva-datepicker
    mode="day"
    alignment="left"
    data-test="day-picker"
    class="day-date-picker"
    theme="theme()"
    [class.table-view]="isTableView()"
    [displayDate]="selectedDate"
    [ngModel]="selectedDate"
    [placeholder]="placeholder()"
    [config]="dayDatePickerConfig"
    [size]="size()"
    (ngModelChange)="changeDate($event)"
    (open)="onOpen()"
/>
