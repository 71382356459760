import { createAction, props } from '@ngrx/store';

import { Crop } from '@priva/masterdata';

export const initializeFeatureFlags = createAction('[Crops-page] Initialize feature flags');

export const loadFeatureFlagsSuccess = createAction(
    '[Crops-page] Load feature flags success',
    props<{ featureFlags: { [key: string]: boolean } }>(),
);

export const loadCropsDataIfTriggered = createAction(
    '[Crops-page] If triggered: load crops from store and validate route',
);

export const loadCropsAndDefinitionsIfNeeded = createAction(
    '[Crops-page] Load crops and definitions if needed',
);

export const validateAndProcessRouteData = createAction(
    '[Crops-page] Select validation route',
    props<{ cropId: string; crops: Crop[] }>(),
);

export const validateCrop = createAction(
    '[Crops-page] Check if crop exists and change route if not',
    props<{ cropId: string; crops: Crop[] }>(),
);

export const validateActiveCrops = createAction(
    '[Crops-page] Check if current crops and change route if not',
    props<{ crops: Crop[] }>(),
);

export const resetTriggeredState = createAction('[Crops-page] Reset triggered state');

export const navigateToUrl = createAction('[Crops-page] Navigate to new route', props<{ url: string }>());
